import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline, pensifyLogoLong, pensifyScreenshot } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

const dummyKlantVerhaal = {
  content: [{
    title: 'Over Pensify',
    description: 'Via Pensify kunnen werkgevers flexibel een pensioenregeling aanbieden aan hun werknemers, waarbij niet iedereen hoeft mee te doen. Werknemers kunnen eenvoudig extra inleggen via de Pensify-app, die ook de aansluiting op hun loonstrook toont en fiscale voordelen biedt.'
    }, {
      title: 'De werking van de koppeling met Pensify',
      description: 'Om gebruik te maken van de koppeling tussen Pensify en Employes, kan de koppeling worden geautoriseerd op het platform van Pensify. Na autorisatie worden de werknemers automatisch ingeladen bij Pensify en kan de werkgever een pensioenplan koppelen aan de werknemer.'
    }, {
      title: 'Functies van de koppeling',
      list: ['Automatisch inladen van werknemers',
      'Automatische verwerking op de loonstrook',
      'Salariskoppeling zorgt voor automatische updates bij salariswijzigingen']
    },{
      image: pensifyScreenshot
    }, {
        title: 'Beschikbaarheid en tarieven van de koppeling',
        description: 'De koppeling tussen Employes en Pensify wordt binnenkort verwacht. De koppeling is geheel gratis!'
  }]
}

class Pensify extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Pensify & Employes | Salarisadministratie</title>
            <meta name="description" content="Automatische verwerking op loonstrook en automatisch inladen werknemers via de handige Pensify Employes koppeling"/>
            <meta itemprop="name" content="Koppeling Pensify & Employes | Salarisadministratie" />
            <meta itemprop="description" content="Automatische verwerking op loonstrook en automatisch inladen werknemers via de handige Pensify Employes koppeling"/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling pensify padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Pensify aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="logo employes"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={pensifyLogoLong} alt="afbeelding Pensify"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                           <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <p><span> Heb je interesse in de Pensify koppeling? </span>
                  <a
                  href="https://pensify.pipedrive.com/scheduler/jqvKrmHZ/afspraak-standaard"
                  target="_blank"
                  rel="nofollow"
                  className="link blue">
                    Neem contact op
                    </a></p>
                </div>
              </div>
            </div>
          </div>


          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/"className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Pensify.propTypes = {
  data: PropTypes.object
}

export default Pensify
